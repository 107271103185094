const translationRU = {
    AndijonDavlatsUniversiteti:"Андижанский государственный",
    univer:"университет",

    MainPage :"Главная страница",
    Doctorate:"Докторантура",
    Regulation:"Устав",
    ListofRequiredDocuments:"Список необходимых документов",
    Quota:"Квота",
    Quota2024:"Квота 2024",
    Quota2025:"Квота 2025",
    SpecialtiesOfDoctorate :"Научные специальности докторантуры",
    Doctorates:"Докторанты",
    QualificationExams :"Квалификационные экзамены ",
    ScientificCouncil :"Научные советы",
    ScientificAdvice:"Научные советы",
    ScientificAndTechnicalCouncil:"Научно-технический совет",
    ScientificProjects:"Научные проекты",
    GovernmentProject:"Государственные проекты",
    InternationalProjects:"Зарубежные проекты",
    ObjectsOfIntellectualProperty:"Объекты интеллектуальной собственности",
    Patents:"Патенты",
    Certificates:"Сертификаты ",
    Journals:"Журналы",
    HumanitarianResearch:"Гуманитарные исследования",
    pedagogicalResearch:"педагогическое исследование",
    ChemicalResearch:"Химические исследования",
    BiologicalResearch:"Биологические исследования",
    PhysicsAndMathematics:"Физико-математические исследования",
    TalentedStudents:"Талантливые студенты",
    ScientificCircles:"Научные кружки",
    ScholarshipByTheGovernments:"Стипендиат ",
    ScientificConferences:"Научные конференции",

    CommodityCHemistryAndFolkMedicine:"Товарная химия и народная медицина",
    HistoryOfUzbekistan:"История Узбекистана",
    Management:"Управление",
    UzbekLanguage:"Узбекский язык",
    UzbekLiterature:"Узбекская литература",
    SocialPhilosophy:"Социальная философия",
    inBiologicalSciencesv:"в биологических науках",

    StateGrant:"Государственный грант",
    ForeignGrant:"Зарубежные проекты ",

    ScientificAndTechnicalCouncil:"Научно-технический совет",
    StatuteOfTHeScientificAndTechnicalCouncil:"Положение о Научно-техническом совете",
    CompositionOfTHeScientificAndTechnicalCouncil:"Состав Научно-технического совета",
    ScientificConferences:"Научные конференции",
} 

export default translationRU;